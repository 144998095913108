<template>
  <el-scrollbar>
    <div class="encyA_body_all">
      <div
        style="
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
        "
      >
        <div class="encyA_btn">
          <el-page-header title="返回" content="添加蔬菜百科" @back="goBack" />
          <!--          <div class="encyA_title">请输入相关信息</div>-->
        </div>
        <div style="width: 40%; margin-top: 20px">
          <el-form ref="formRef" :model="form" label-width="120px">
            <el-form-item label="id">
              <el-input v-model="form.vegetables_id"></el-input>
            </el-form-item>
            <el-form-item label="中文名">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="别称">
              <el-input v-model="form.alias"></el-input>
            </el-form-item>
            <el-form-item label="科">
              <el-input v-model="form.section"></el-input>
            </el-form-item>
            <el-form-item label="生长习性">
              <el-input v-model="form.habit" type="textarea"></el-input>
            </el-form-item>
            <el-form-item label="形态特征">
              <el-input v-model="form.features" type="textarea"></el-input>
            </el-form-item>
            <el-form-item label="图片" prop="picture">
              <el-upload
                class="avatar-uploader"
                action="http://localhost:8002/a/imageUpload.do"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
              >
                <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
              </el-upload>
            </el-form-item>
            <el-form-item label="小图标" prop="icon">
              <el-upload
                class="avatar-uploader"
                action="http://localhost:8002/a/imageUpload.do"
                :show-file-list="false"
                :on-success="handleAvatarSuccess1"
              >
                <img v-if="imageUrl1" :src="imageUrl1" class="avatar" />
                <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
              </el-upload>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit">添加</el-button>
              <el-button @click="exit">取消</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </el-scrollbar>
</template>

<script>
import { reactive, getCurrentInstance } from "vue";
// import { ElMessage } from 'element-plus'
import { Plus } from "@element-plus/icons-vue";
import { ElMessage } from "element-plus";
// import {
//   UploadFile,
//   ElUploadProgressEvent,
//   ElFile,
// } from 'element-plus/es/components/upload/src/upload.type'
export default {
  name: "VencAdd",
  components: {
    Plus,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const form = reactive({
      vegetables_id: "",
      name: "", //中文名
      alias: "", //别称
      section: "", //科
      habit: "", //习性
      features: "", //特征
      picture: "",
      icon: "",
      imageUrl: "",
      imageUrl1: "",
    });
    function goBack() {
      proxy.$router.go(-1);
    }
    function handleAvatarSuccess(res) {
      console.log(res.msg);
      form.picture = res.msg;
    }
    function handleAvatarSuccess1(res) {
      console.log(res.msg);
      form.icon = res.msg;
    }
    function onSubmit() {
      proxy.$http
        .get("/EncyclopediaController/addVegetableEncyclopedia", {
          vegetables_id: form.vegetables_id,
          name: form.name,
          alias: form.alias,
          section: form.section,
          features: form.features,
          habit: form.habit,
          picture: form.picture,
          icon: form.icon,
        })
        .then((res) => {
          console.log(res);
          if (res.code === 1) {
            ElMessage({
              type: "success",
              message: "添加蔬菜百科成功",
            });
            proxy.$router.push("/VegetablesEncyclopedia");
          } else
            ElMessage({
              type: "error",
              message: "添加蔬菜百科失败",
            });
        });
    }
    function exit() {
      proxy.$router.go(-1);
    }
    return {
      form,
      goBack,
      handleAvatarSuccess1,
      handleAvatarSuccess,
      onSubmit,
      exit,
    };
  },
};
</script>

<style>
.encyA_body_all {
  background-color: white;
  padding: 20px 20px 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.encyA_title {
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 2px;
  margin: 20px 20px 10px 20px;
}
.encyA_btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  margin-left: 120px;
  margin-bottom: 20px;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 150px;
  height: 150px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
